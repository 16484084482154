@media screen and (max-width: 600px){
    .mainHeader .header_logo {
        width: 150px;
    }   
}

@media screen and (max-width: 380px){
    .mainHeader .header_logo {
        width: 100px;
    }   
}

@media screen and (max-width: 350px){
    .mainHeader .header_logo {
        width: 45%;
    }   
}
